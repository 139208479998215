import GLightbox from 'glightbox';

const modal = () => {
  if (document.querySelector('[data-glightbox]')) {
    // eslint-disable-next-line no-unused-vars
    const lightbox = GLightbox({
      selector: '[data-glightbox]',
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
    });
  }
};

export default modal;
