const search = () => {
  if (document.querySelector('[data-search]')) {
    const form = document.querySelector('.c-navigation__search-form');
    const button = document.querySelector('.c-navigation__search');
    button.addEventListener('click', (event) => {
      event.preventDefault();
      button.classList.toggle('c-navigation__search--active');
      form.classList.toggle('c-navigation__search-form--active');
    });
  }
};

export default search;
