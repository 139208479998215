import Splide from '@splidejs/splide';

const slider = () => {
  if (document.querySelector('.splide')) {
    new Splide('.splide', {
      updateOnMove: true,
      perMove: 1,
      autoWidth: true,
      start: 1,
      gap: 0,
      focus: 'center',
      pagination: false,
      padding: 20,
    }).mount();
  }
};

export default slider;
