/*
  Project: Naspa
  Author: Jarosław Gąsiorowski
 */

import mobileMenu from './modules/mobileMenu';
import slider from './modules/slider';
import modal from './modules/modal';
import search from './modules/search';

mobileMenu();
slider();
modal();
search();
